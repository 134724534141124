export default [ 
    {
        path: '/site',
        name: 'Site',
        component: () => import('../views/Site/SiteLayout.vue'),
        meta: { 
            requiresAuth: true,
        },
    },
    {
        path: '/auth',
        name: 'Login',
        component: () => import('../components/Auth/Login/index.vue'),
        meta: { 
            requiresAuth: false,
        },
    },
    {
        path: '*',
        redirect: '/auth'
    }
]