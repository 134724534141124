<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'
let _logout = false
import VueCookies from "vue-cookies"

export default {
  name: 'App',
  components: {
    
  },
  async created() {
    axios.interceptors.response.use( (response) =>{
      _logout = false
    return response
    }, async (err)=>{
      if (err.response && err.response.status == 401){
        if (this.$route.path !== '/auth') {
          this.$router.push('/auth')
        }
      }
      return Promise.reject(err)
    })
  }
}
</script>

<style lang="scss">

@import 'design/index.scss';
@import 'design/bancos/icones-bancos-brasileiros.css';

@font-face {
  font-family: 'Berlin Sans FB Local';
  src:  url('design/fonts/BerlinSansFBBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.month-picker__month.selected{
  background-color: $color-success !important;
  box-shadow: inset 0 0 3px $color-success, 0px 2px 5px rgb(85 176 242 / 20%) !important;
}
.month-picker__container{
  width: 372px !important;
}

</style>
