import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const isAuthenticated = store.getters['auth/isLoggedIn']
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !isAuthenticated) {
    if (to.path !== '/auth') {
      next('/auth')
    } else {
      next(false)
    }
  }else {
    next()
  }

})

export default router